import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepContactDetails = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet>
            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Email Address'}
                    name={'email'}
                    id={'email'}
                    type={'email'}
                    autocomplete={'email'}
                    placeholder={'e.g. name@email.com'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Contact Number'}
                    name={'phone'}
                    id={'phone'}
                    autocomplete={'tel'}
                    pattern={'number'}
                    placeholder={'e.g. 07700900796'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Postcode'}
                    name={'postcode'}
                    id={'postcode'}
                    autocomplete={'postal-code'}
                    placeholder={'e.g. ZE2 7UL'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepContactDetails
